import { ButtonInterface, ConfigInterface, StepInterface } from '@/model';
import moment from 'moment';
import Vue from 'vue';
import { Store } from 'vuex';
import NoopHelper from '../NoopHelper';
import ConfigLandgoedborkerheide from './Setup/config-landgoedborkerheide';
import configUiterwaarde from './Setup/config-uiterwaarde';
import configVreehorst from './Setup/config-vreehorst';
import configReestland from './Setup/config-reestland';
import configKostverloren from './Setup/config-kostverloren';
import configLutjeKossink from './Setup/config-lutjekossink';
import { TommyClient } from './TommyClient';

export class TommyHelper extends NoopHelper {
  public client: TommyClient;

  constructor(public store: Store<any>) {
    super(store);

    if (store.getters.meta.accommodationGroups || false) {
      store.commit('SET_TOMMY_ACCOMMODATIONGROUPS', store.getters.meta.accommodationGroups);
    }

    this.client = new TommyClient(store, '' + store.getters.meta.token);
  }

  async setup() {
    await this.client.load();
  }

  getClient(): any {
    return this.client;
  }

  provideTranslations(): any {
    const baseTranslations: any = require('./Setup/translations-base.ts');
    const result: any = Object.assign({}, baseTranslations);

    const overrideTranslations: any = require('./Setup/translations-override.ts');
    const user: any = this.store.getters.meta.user || false;

    if (false !== user && typeof overrideTranslations[user] !== 'undefined') {
      const userTranslations: any = overrideTranslations[user];
      Object.keys(userTranslations).forEach((key: any) => {
        const userTranslation: any = userTranslations[key];

        Object.keys(userTranslation).forEach((translationKey: any) => {
          if (!result[key] || !result[key][translationKey]) {
            return;
          }

          result[key][translationKey] = userTranslation[translationKey];
        });
      });
    }

    return result;
  }

  async overrideStep(step: StepInterface, selection: any, choices: any): Promise<boolean | StepInterface> {
    // re-check current date and update if needed (client is running 24-7 and won't update the date saved in the store).
    const updateInitialDate: any = this.store.getters.meta.updateInitialDate;
    const currentStartDate: any = this.store.getters.startDate;
    if (false !== updateInitialDate && currentStartDate) {
      const newStartDate = moment();
      if (process.env.VUE_APP_DATE_OFFSET) {
        newStartDate.add(parseInt(process.env.VUE_APP_DATE_OFFSET), 'days');
      }

      if (currentStartDate !== newStartDate.format('YYYY-MM-DD')) {
        console.error('update start date to', newStartDate.format('YYYY-MM-DD'));
        this.store.commit('SET_START_DATE', newStartDate.format('YYYY-MM-DD'));
      }
    }

    if (step.code === 'intro' && 0 !== this.store.getters.meta.entrypoints.length) {
      const newButtons: ButtonInterface[] = [];
      step.buttons.forEach((button: ButtonInterface) => {
        if (this.store.getters.meta.entrypoints.includes(button.code)) {
          newButtons.push(button);
        }
      });

      step.buttons = newButtons;

      return step;
    }

    if (step.code === 'language') {
      const enabledLanguageCodes = this.store.getters.apiLanguages.map((language: any) =>
        Vue.filter('map_tommy_locale')(language.code),
      );

      // filter non-enabled language buttons.
      step.buttons = step.buttons.filter((btn: any) => {
        return enabledLanguageCodes.includes(Vue.filter('map_tommy_locale')(btn.code));
      });

      const buttonCodes: any = step.buttons.map((button: ButtonInterface) => Vue.filter('map_tommy_locale')(button.code));

      enabledLanguageCodes.forEach((enabledLanguageCode: any) => {
        if (!buttonCodes.includes(enabledLanguageCode)) {
          console.error('Account expects language code "' + enabledLanguageCode + '" but is not configured in wizard steps.');
        }
      });

      return step;
    }

    // skip step example
    // if ('xxx' === step.code) {
    //   if (condition) {
    //     step.skip = {};
    //   }
    //
    //   return step;
    // }

    // skip extras step when no action code or visible articles remain.
    if ('personaldata' === step.code) {
      const stepFields: any = [];

      // compare and apply config form fields and api form field settings.
      step.options.widget.options.fields.forEach((formField: any) => {
        if (true === (formField.custom || false)) {
          console.debug('cleanup previous custom field', formField.name);
          return;
        }

        if (!formField.options) {
          formField.options = {};
        }

        let found: boolean = false;
        const forceShow = true === formField.forceShow || false;

        this.store.getters.apiPersonDetails.persoonsgegevens.forEach((personalDetail: any) => {
          const isActive: boolean = true === personalDetail.actief || 1 === parseInt(personalDetail.actief);
          const isRequired: boolean = true === personalDetail.verplicht || 1 === parseInt(personalDetail.verplicht);
          const nameMatches: boolean =
            formField.name.toLowerCase() === personalDetail.naam.toLowerCase() ||
            formField.label.toLowerCase() === personalDetail.naam.toLowerCase();
          const aliasMatches: boolean = ('' + formField.alias).toLowerCase() === personalDetail.naam.toLowerCase();

          if (!found && isActive && (nameMatches || aliasMatches)) {
            found = true;
            formField.options.translate = true;

            if (isRequired) {
              formField.required = true;
            }
          }
        });

        if (!found && !forceShow) {
          console.warn('disable inactive customer field', formField.name);
        } else if ('kenteken2' === formField.name) {
          const arrangementsWithLicensePlate2 = this.store.getters.meta.arrangementsWithLicensePlate2;

          if (arrangementsWithLicensePlate2.includes(`${this.store.getters.meta.arrangementId}`)) {
            console.debug('show kenteken2 field', formField.name);
            stepFields.push(formField);
          } else {
            console.warn('disable kenteken2 customer field', formField.name);
          }
        } else {
          console.debug('show customer field', formField.name);
          stepFields.push(formField);
        }
      });

      const locale = this.store.getters.locale;
      this.store.getters.apiPersonDetails.vrijevelden.forEach((customField: any) => {
        console.warn('custom field', customField);
        if (1 === parseInt(customField.actief)) {
          console.warn('active', customField.id, customField.naam);

          stepFields.push({
            custom: true,
            type: 'text',
            label: (customField.vertalingen || [])[locale] || customField.naam,
            name: 'custom_' + customField.id,
            required: false,
            forceShow: true,
            options: {
              translate: false,
            },
          });
        }
      });

      step.options.widget.options.fields = stepFields;

      return step;
    }

    // skip extras step when no action code or visible articles remain.
    if ('extras' === step.code) {
      if (this.store.getters.articlesShown.length <= 0 && this.store.getters.apiSettings.actiecodeVerplicht !== true) {
        step.skip = {};
      }

      return step;
    }

    return false;
  }

  async stepDone(step: StepInterface, selection: any, choices: any): Promise<any> {
    if ('language' === step.code) {
      this.store.dispatch('updateLocale', choices.language.button.code);

      // todo: payment due date ?
      const paymentDueDate: any = moment(this.store.getters.startDate).add(30, 'days');

      this.store.commit('UPDATE_TRANSLATION_CONTEXT', {
        language: choices.language.button.code,
        payment_due_date: Vue.filter('date_format')(paymentDueDate, { type: 'date-full' }),
      });
    }

    if ('intro' === step.code && 'start-reserved' === choices.intro.button.code) {
      this.store.dispatch('removeStepsAfter', {
        code: step.code,
      });
      this.store.commit('ADD_STEP', {
        code: step.code,
        buttons: [],
        title: 'Ik heb gereserveerd',
        layout: {
          rows: [
            {
              columns: [
                {
                  type: 'text',
                  classes: ['col-12'],
                  data: 'reserved-text',
                },
              ],
            },
          ],
        },
      } as StepInterface);
    }

    if ('travelgroup' === step.code) {
      const travelGroupData: any = choices.travelgroup.data;
      const tommyTravelGroup: any = {};
      Object.keys(travelGroupData).forEach((key: any) => {
        tommyTravelGroup[key] = travelGroupData[key].value;
      });

      this.store.commit('SET_TOMMY_TRAVELGROUP', tommyTravelGroup);
      await this.store.dispatch('updateTommyAccommodations');
    }

    if ('reductionpass' === step.code) {
      const chosenPriceTrigger: any = choices.reductionpass.data;

      if (chosenPriceTrigger) {
        this.store.commit('SET_TOMMY_PRICE_TRIGGER', chosenPriceTrigger);
      } else {
        this.store.commit('SET_TOMMY_PRICE_TRIGGER', null);
      }
    }

    if ('floorplan' === step.code) {
      const chosenLocation: any = choices.floorplan.data;

      this.store.commit('UPDATE_TRANSLATION_CONTEXT', {
        location_name: chosenLocation.naam,
      });

      this.store.commit('SET_TOMMY_LOCATION', chosenLocation.id);

      // refresh widget boeken API endpoint persondetails, based on selected accommodation.
      await this.client.getWidgetBoeken().then(async (widgetResponse: any) => {
        this.store.commit('SET_TOMMY_API_PERSONDETAILS', widgetResponse.data.variables.persoonsgegevens);
      });
    }

    if ('extras' === step.code) {
      const extrasData: any = choices.extras.data || false;
      const result: any = { articles: {}, characteristics: {} };

      if (false !== extrasData) {
        const keys: any = Object.keys(extrasData);
        keys.forEach((key: any) => {
          const set: any = extrasData[key] || {};
          Object.keys(set).forEach((subKey: any) => {
            const value: number = parseInt(set[subKey]);
            if (value > 0) {
              result[key][subKey] = value;
            }
          });
        });
      }

      this.store.commit('SET_TOMMY_EXTRAS', result);
    }

    if ('duration' === step.code) {
      this.store.commit('UPDATE_TRANSLATION_CONTEXT', {
        arrival_date: choices.duration.data.start_full,
        departure_date: choices.duration.data.end_full,
      });
    }

    if ('accommodation' === step.code) {
      this.store.commit('SET_TOMMY_ACCOMMODATION', choices.accommodation.data.code);
      this.store.commit('UPDATE_TRANSLATION_CONTEXT', {
        accommodation_name: choices.accommodation.data.label,
        arrival_time: choices.accommodation.data.arrivalTime,
        departure_time: choices.accommodation.data.departureTime,
      });

      await this.store.dispatch('updateTommyArticles');
      await this.store.dispatch('updateTommyCharacteristics');
    }

    if ('personaldata' === step.code) {
      this.store.commit('UPDATE_TRANSLATION_CONTEXT', {
        email: choices.personaldata.data.email,
        firstname: choices.personaldata.data.voornaam,
        birthdate: choices.personaldata.data.birthdate,
        infix: choices.personaldata.data.tussenvoegsel,
        surname: choices.personaldata.data.achternaam,
        license_plate: choices.personaldata.data.kenteken,
        housenumber: choices.personaldata.data.huisnummer,
        place: choices.personaldata.data.plaats,
        street: choices.personaldata.data.straat,
        zipcode: choices.personaldata.data.postcode,
        country: choices.personaldata.data.land,
      });

      return true;
    }

    if ('confirm' === step.code) {
      let success: boolean = true;
      if ('1' !== (process.env['VUE_APP_CYPRESS_RUN'] || false)) {
        const response: any = await this.client.createBooking();
        const data: any = response || {};
        success = true === (data.success || false);

        let msg: any = data.msg || false;
        if (!success) {
          if (typeof msg !== 'string' && typeof msg.length === 'number') {
            msg = msg[0];
          } else if (false === msg) {
            msg = 'Er ging iets mis bij het verzenden';
          }

          this.store.dispatch('showPopup', msg);
        }

        if (typeof response.data.ideal !== 'undefined' && response.data.ideal) {
          document.location = response.data.ideal;
        }
      }

      return success;
    }

    return true;
  }

  getConfig(): ConfigInterface {
    const user: string = ('' + this.store.getters.meta.user).toLowerCase();

    switch (user) {
      case 'borkerheide':
        return ConfigLandgoedborkerheide;
      case 'vreehorst':
        return configVreehorst;
      case 'reestland':
        return configReestland;
      case 'kostverloren':
        return configKostverloren;
      case 'lutjekossink':
        return configLutjeKossink;
      case 'uiterwaarde':
        return configUiterwaarde;
    }

    throw "Failed to get config for user '" + user + "'";
  }
}
