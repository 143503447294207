
import Vue from 'vue';
import NumberToggle from '@/components/NumberToggle.vue';
import { wizardProvider } from '@/wizard';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    NumberToggle,
  },
  props: {
    widget: {
      type: Object,
      default: () => false as any,
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      ready: false,
      pets: 0 as number,
    };
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    triggerClick: function(): any {
      this.$emit('choose', { data: this.pets });
    },
  },
  computed: {
    minPets(): number {
      return this.petsConfig.min || 0;
    },
    maxPets(): number {
      return this.petsConfig.max || 2;
    },
    ...mapGetters(['locale', 'petsConfig']),
  },
});
