// themes
require('../assets/tommy/_theme-vreehorst.scss');
require('../assets/tommy/_theme-vreehorstcp.scss');
require('../assets/tommy/_theme-landgoedborkerheide.scss');
require('../assets/tommy/_theme-reestland.scss');
require('../assets/tommy/_theme-kostverloren.scss');
require('../assets/tommy/_theme-lutjekossink.scss');
require('../assets/tommy/_theme-uiterwaarde.scss');

import '../wizard/Tommy/main';
