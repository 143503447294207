const noTranslation: any = {
  nl: '',
  de: '',
  fr: '',
  en: '',
};

const overrideTranslations: any = {
  uiterwaarde: {
    title: {
      nl: 'Parkeerabonnement',
      de: 'Parkeerabonnement',
      fr: 'Parkeerabonnement',
      en: 'Parkeerabonnement',
    },
    arrival: {
      nl: 'Geldig van',
      de: 'Geldig van',
      fr: 'Geldig van',
      en: 'Geldig van',
    },
    departure: {
      nl: 'Geldig tot',
      de: 'Geldig tot',
      fr: 'Geldig tot',
      en: 'Geldig tot',
    },
    'payment-later': {
      nl: 'Na bevestigen kunt u direct de betaling verrichten.',
      de: 'Na bevestigen kunt u direct de betaling verrichten.',
      fr: 'Na bevestigen kunt u direct de betaling verrichten.',
      en: 'Na bevestigen kunt u direct de betaling verrichten.',
    },
    'btn-complete': {
      nl: 'Bevestigen',
      de: 'Bevestigen',
      fr: 'Bevestigen',
      en: 'Bevestigen',
    },
    'finish-text-1': {
      nl: '',
      de: '',
      fr: '',
      en: '',
    },
    'finish-text-2': {
      nl: '',
      de: '',
      fr: '',
      en: '',
    },
    'pin-title': {
      nl: '',
      de: '',
      fr: '',
      en: '',
    },
    'pin-description': {
      nl: '',
      de: '',
      fr: '',
      en: '',
    },
    'pin-button': {
      nl: '',
      de: '',
      fr: '',
      en: '',
    },
  },
  vreehorst: {
    before: {
      nl: 'voor',
      de: 'vor',
      fr: 'pour',
      en: 'before',
    },
    after: {
      nl: 'na',
      de: 'nach',
      fr: 'après',
      en: 'after',
    },
    'confirm-title': {
      ...noTranslation,
    },
    // no nav-guide for Vreehorst
    'nav-guide': {
      ...noTranslation,
    },
    // no intro-text-2 for vreehorst
    'intro-text-2': {
      ...noTranslation,
    },
    'payment-later': {
      ...noTranslation,
    },
    'pin-title': {
      ...noTranslation,
    },
    'pin-description': {
      ...noTranslation,
    },
    'finish-text-1': {
      nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>Uw locatie <strong class="location">%accommodation_name% (%location_name%)</strong> is beschikbaar voor u vanaf %arrival_time%.</p>
        <p>Totaalbedrag: %total_receipt_price_formatted%.</p>
        <p>U ontvangt binnen enkele minuten een bevestiging per mail met betaallink.</p>
        <p>
            Locatie: %accommodation_name% (%location_name%)<br />
            Aankomst: %arrival_date% na %arrival_time%<br />
            Vertrek: %departure_date% voor %departure_time%
        </p>
        <p>U kunt door de slagboom met uw kenteken: %license_plate%</p>
        <p>Het Camperpark is bereikbaar via de Vreehorstweg.</p>
    `,
      de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Ihr Platz <strong class="location">%accommodation_name% (%location_name%)</strong> steht Ihnen ab %arrival_time% zur Verfügung.</p>
        <p>Gesamtbetrag: %total_receipt_price_formatted%.</p>
        <p>Sie erhalten innerhalb weniger Minuten eine Bestätigung per E-Mail mit einem Zahlungslink.</p>
        <p>
            Platznummer: %accommodation_name% (%location_name%)<br />
            Anreise: %arrival_date% nach %arrival_time%<br />
            Abreise: %departure_date% vor %departure_time%
        </p>
        <p>Sie können mit Ihrem Nummernschild durch die Schranke fahren: %license_plate%</p>
        <p>Der Camperpark ist über den Vreehorstweg erreichbar.</p>
    `,
      en: '',
      fr: '',
    },
    'finish-text-2': {
      nl: '<h1 class="xl">Uw vakantie op %park_name% is begonnen!</h1>',
      de: '<h1 class="xl">Ihr Urlaub auf %park_name% hat begonnen!</h1>',
      fr: '<h1 class="xl">Vos vacances sur %park_name% ont commencé!</h1>',
      en: '<h1 class="xl">Your holiday on %park_name% has begun!</h1>',
    },
    'intro-text-1': {
      nl: `
        <h2>Boek een kampeer- of camperplaats</h2>
        <p>Boek een plaats en check direct in. U betaald vooraf de nachten die u boekt. Bij eerder vertrek vindt geen restitutie plaats.</p>
        <p>De slagboom werkt op kentekenherkenning.</p>
      `,
      de: `
        <h2>Campingplatz oder Wohnmobilstellplatz buchen</h2>
        <p>Reservieren Sie einen Platz und checken Sie sofort ein. Sie zahlen im Voraus für die von Ihnen gebuchten Nächte. Bei vorzeitiger Abreise gibt es keine Rückerstattung.</p>
        <p>Die Schranke funktioniert bei der Nummernschilderkennung.</p>`,
      en: `
        <h2>Book a camping pitch or motorhome pitch</h2>
        <p>Book a seat and check in immediately. You pay in advance for the nights you book. In case of early departure there will be no refund.</p>
        <p>The barrier works on license plate recognition.</p>
      `,
      // todo: translate
      fr: '',
    },
  },
  vreehorstcp: {
    before: {
      nl: 'voor',
      de: 'vor',
      fr: 'pour',
      en: 'for',
    },
    after: {
      nl: 'na',
      de: 'nach',
      fr: 'après',
      en: 'after',
    },
    'confirm-title': {
      ...noTranslation,
    },
    // no nav-guide for Vreehorst
    'nav-guide': {
      ...noTranslation,
    },
    // no intro-text-2 for vreehorst
    'intro-text-2': {
      ...noTranslation,
    },
    'payment-later': {
      ...noTranslation,
    },
    'pin-title': {
      ...noTranslation,
    },
    'pin-description': {
      ...noTranslation,
    },
    'finish-text-1': {
      nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>Uw locatie <strong class="location">%accommodation_name% (%location_name%)</strong> is beschikbaar voor u vanaf %arrival_time%.</p>
        <p>Totaalbedrag: %total_receipt_price_formatted%.</p>
        <p>U heeft 30 seconden om de betaling te voldoen via de betaalautomaat.</p>
        <p>Is de betaling niet gelukt? U ontvangt binnen enkele minuten een bevestiging per mail met betaallink.</p>
        <p>
            Locatie: %accommodation_name% (%location_name%)<br />
            Aankomst: %arrival_date% na %arrival_time%<br />
            Vertrek: %departure_date% voor %departure_time%
        </p>
        <p>U kunt door de slagboom met uw kenteken: %license_plate%</p>
    `,
      de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Ihr Platz <strong class="location">%accommodation_name% (%location_name%)</strong> steht Ihnen ab %arrival_time% zur Verfügung.</p>
        <p>Gesamtbetrag: %total_receipt_price_formatted%.</p>
        <p>Sie haben 30 Sekunden Zeit, um die Zahlung zu leisten.</p>
        <p>Ist die Zahlung nicht erfolgreich? Sie erhalten dann innerhalb weniger Minuten eine Bestätigung per E-Mail mit einem Zahlungslink.</p>
        <p>
            Platznummer: %accommodation_name% (%location_name%)<br />
            Anreise: %arrival_date% nach %arrival_time%<br />
            Abreise: %departure_date% vor %departure_time%
        </p>
        <p>Sie können mit Ihrem Nummernschild durch die Schranke fahren: %license_plate%</p>
    `,
      en: '',
      fr: '',
    },
    'finish-text-2': {
      nl: '<h1 class="xl">Uw vakantie op %park_name% is begonnen!</h1>',
      de: '<h1 class="xl">Ihr Urlaub auf %park_name% hat begonnen!</h1>',
      fr: '<h1 class="xl">Vos vacances sur %park_name% ont commencé!</h1>',
      en: '<h1 class="xl">Your holiday on %park_name% has begun!</h1>',
    },
    'intro-text-1': {
      nl: `
        <h2>Boek een kampeer- of camperplaats</h2>
        <p>Boek een plaats en check direct in. U betaald vooraf de nachten die u boekt. Bij eerder vertrek vindt geen restitutie plaats.</p>
        <p>De slagboom werkt op kentekenherkenning.</p>
      `,
      de: `
        <h2>Campingplatz oder Wohnmobilstellplatz buchen</h2>
        <p>Reservieren Sie einen Platz und checken Sie sofort ein. Sie zahlen im Voraus für die von Ihnen gebuchten Nächte. Bei vorzeitiger Abreise gibt es keine Rückerstattung.</p>
        <p>Die Schranke funktioniert bei der Nummernschilderkennung.</p>`,
      en: `
        <h2>Book a camping pitch or motorhome pitch</h2>
        <p>Book a seat and check in immediately. You pay in advance for the nights you book. In case of early departure there will be no refund.</p>
        <p>The barrier works on license plate recognition.</p>
      `,
      // todo: translate
      fr: '',
    },
  },
  borkerheide: {
    'accommodation-title': {
      nl: 'Kies een type',
      de: 'Wählen Sie die Art Ihres Stellplatzes',
      en: 'Choose a type',
      // todo: translate
      fr: '',
    },
    'available-results': {
      nl: 'Beschikbaar',
      de: 'Verfügbare Optionen',
      en: 'Available at',
      // todo: translate
      fr: '',
    },
    'floorplan-title': {
      nl: 'Plattegrond',
      de: 'Lageplan',
      fr: 'Carte',
      en: 'Map',
    },
    // no finish-text-2 for borkerheide
    'finish-text-2': {
      ...noTranslation,
    },
    'intro-booking': {
      nl: 'Start boeking',
      de: 'Buchung starten',
      en: 'Start Booking',
      fr: 'Commencer la réservation',
    },
    'intro-text-1': {
      nl: `
        <h2>Boek een kampeer- of camperplaats.</h2>
        <p>U kunt hier een kampeer- of camperplaats boeken en direct inchecken. Met een camper kunt u ook op een kampeerplaats staan. U kunt gelijk betalen met de pin, of later via de mail. U ontvangt dan een betaal-link via Ideal.</p>
      `,
      de: `
        <h2>Buchen Sie einen Zelt-, Wohnwagen-, oder Wohnmobil-Stellplatz.</h2>
        <p>Sie können hier einen Stellplatz buchen und direkt einchecken. Mit Ihrem Wohnmobil dürfen Sie sich auch auf einen Zelt-, oder Wohnwagenstellplatz stellen. Sie können sofort mit EC Karte oder später über Mail bezahlen - Sie bekommen dann einen Link zum Bezahlen per Ideal zugesendet.</p>
      `,
      en: `
        <h2>Book a camping- or motorhome pitch</h2>
        <p>You can book a camping- or motorhome pitch here and check in directly. You can pay with mobile banking or at a later time at the reception.</p>
        <p>Extensive information about our camping- and camper pitches can be found at the reception or check %website_url%</p>
    `,
      fr: ``,
    },
    'intro-text-2': {
      nl: `
      <p>De uitcheque tijd is 12.00 uur. Mocht u later willen vertrekken (alleen buiten het hoogseizoen) informeer dan bij de beheerder.</p>
      <p>Uitgebreide informatie over onze camping vindt u op %website_url%.</p>
    `,
      de: `<p>Sie müssen den Platz bis 12 Uhr verlassen. Falls sie erst später abreisen möchten (nur außerhalb der Hochsaison möglich), setzen Sie sich bitte mit dem Campingplatzbetreiber in Verbindung. Ausführliche Informationen über unseren Campingplatz finden Sie auf %website_url%.</p>`,
      en: `<p>Departure or check out time is 12.00 pm. If you want to check out later ( only possible in low season) please contact the manager at the reception.</p>`,
      fr: ``,
    },
    'finish-text-1': {
      nl: `
        <h2>Uw boeking is succesvol verlopen.</h2>
        <p>U kunt ook gebruik maken van ons WiFi- netwerk. Het wachtwoord is: Landgoed2020</p>
        <p>Mocht u vragen hebben kunt u ons bellen 0593 331546</p>
        <p>Voor overige informatie verwijzen wij naar onze website: www.landgoedborkerheide.nl</p>
        <p>Wij wensen u een aangenaam verblijf op Landgoed Börkerheide.</p>
    `,
      de: `<h2>Ihre Buchung war erfolgreich.</h2>
 <p>Sie können auch unser WiFi-Netzwerk nutzen. Das Passwort lautet: Estate2020</p>
 <p>Wenn Sie Fragen haben, können Sie uns unter 0593 331546 anrufen</p>
 <p>Für weitere Informationen verweisen wir auf unsere Website: www.landgoedborkerheide.nl.</p>
 <p>Wir wünschen Ihnen einen angenehmen Aufenthalt in Landgoed Börkerheide.</p>`,
      fr: `<h2>Votre réservation est réussie.</h2>
 <p>Vous pouvez également utiliser notre réseau WiFi. Le mot de passe est : Estate2020</p>
 <p>Si vous avez des questions, vous pouvez nous appeler au 0593 331546</p>
<p>Pour d'autres informations, nous vous renvoyons à notre site web : www.landgoedborkerheide.nl.</p>
 <p>Nous vous souhaitons un agréable séjour à Landgoed Börkerheide.</p>`,
      en: `<h2>Your booking has been successful.</h2>
 <p>You can also use our WiFi network. The password is: Estate2020</p>
<p>If you have any questions you can call us 0593 331546</p>
<p>For other information we refer to our website: www.landgoedborkerheide.nl.</p>
<p>We wish you a pleasant stay at Landgoed Börkerheide.</p>`,
    },
    'payment-later': {
      nl: 'Betalen kan met mobiel bankieren of op een later tijdstip bij de receptie.',
      de: 'Die Zahlung kann per online Banking (oder Sie bekommen eine Mail mit einem link, um über  IDEAL zu bezahlen)',
      fr: `Le paiement peut être effectué par la banque mobile ou à une heure ultérieure à la réception.`,
      en: `Payment can be made by mobile banking or at a later time at reception.`,
    },
    salution: {
      nl: 'Aanhef',
      de: 'Anrede',
      fr: 'Salutation',
      en: 'Titel',
    },
  },
  reestland: {
    'confirm-title': {
      nl: '',
      de: '',
      en: '',
      fr: '',
    },
  },
  kostverloren: {
    'intro-text-1': {
      nl: `<h2>Boek een Camperplaats</h2>
           <p>U kunt hier een camperplaats boeken en direct toepassen. Betalen kan met uw pin.</p>
           <p>Uitgebreide informatie over onze camperplaatsen vind u bij de receptie op de camping of check %website_url%</p>`,
    },
    'intro-text-2': {
      nl: `<h3>Accommodatie boeken</h3>
           <p>Wilt u reserveren met een caravan dan meld u zich bij de receptie of u boekt telefonisch of online via %website_url%.</p>`,
    },
    'payment-later': {
      nl: `U kunt nu betalen met pin of voor contant melden bij receptie.`
    },
    'nav-guide': {
      nl: `Loop je tegen een probleem aan? Meld je dan bij de receptie of bel als de receptie is gesloten met nummer 06-21390264`
    },
    'finish-text-1': {
      nl: `<h2>Boeking succesvol afgerond</h2>
            <p>De boeking is afgerond en u bent ingecheckt op locatie <strong class="location">%accommodation_name% (%location_name%)</strong>. U ontvangt binnen enkele minuten een bevestiging per mail. U betaald ter plaatse met pin of contant bij de receptie.</p>
            <p>Als u toch langer wilt blijven dan kan u op de datum van vertrek nogmaals een boeking maken en betalen.</p>`,
    },
    'finish-text-2': {
      nl: '<h1 class="xl">Uw vakantie op %park_name% is begonnen!</h1>',
    },
  },
};

module.exports = overrideTranslations;
