
import Vue from 'vue';
import { wizardProvider, TommyHelper } from '@/wizard';
import moment from 'moment';
import { TommyClient } from '../TommyClient';
import ReceiptModel from './model/ReceiptModel';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      wizardProvider: wizardProvider,
      ready: false,
    };
  },
  mounted() {
    const meta: any = this.$store.getters.meta;
    const usePin: boolean = meta.usePin || false;

    const choices: any = this.$store.getters.choices;

    if (usePin) {
      this.ready = true;
    }
  },
  methods: {
    quit: function() {
      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('updateMountKey');
      this.$store.commit('SET_STEP_NUMBER', 1);
    },
  },
  computed: {
    ...mapGetters(['translationContext']),
    totalAmount(): number {
      return this.translationContext['total_receipt_price'] || 0;
    },
  },
});
