
import Vue from 'vue';
import Wizard from '@/components/Wizard.vue';
import TimeTicker from '@/components/TimeTicker.vue';
import TimeoutPopup from '@/wizard/Tommy/Components/TimeoutPopup.vue';
import { mapGetters } from 'vuex';
import { wizardProvider, TommyHelper } from '@/wizard/';
import moment from 'moment';

moment.updateLocale('nl', { week: { dow: 0, doy: 4 } });
moment.updateLocale('en', { week: { dow: 0, doy: 4 } });
moment.updateLocale('de', { week: { dow: 0, doy: 4 } });

moment.locale('nl');

export default Vue.extend({
  name: 'App',
  components: {
    Wizard,
    TimeTicker,
    TimeoutPopup,
  },
  props: {
    usePin: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    theme: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    colorLoader: {
      type: String,
      required: false,
    },
    colorZoomBg: {
      type: String,
      required: false,
    },
    colorZoomFg: {
      type: String,
      required: false,
    },
    mapsKey: {
      type: String,
      required: false,
    },
    entrypoints: {
      type: Array,
      required: false,
      default: () => ['start-booking', 'start-reserved'],
    },
    travelGroupLimits: {
      type: Object,
      required: false,
      default: () => {},
    },
    accommodationGroups: {
      type: Array,
      required: false,
      default: () => [] as any,
    },
    maxDuration: {
      type: Number,
      required: false,
      default: () => null as any,
    },
    salesChannel: {
      type: Number,
      required: false,
      default: () => null as any,
    },
    translations: {
      type: Object,
      required: false,
      default: () => {},
    },
    petsConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          articles: [] as any,
          max: 0 as number,
        };
      },
    },
    alternativeResults: {
      type: Boolean,
      required: true,
      default: () => {
        return false as boolean;
      },
    },
    accommodationLabels: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    useMarkerClusterer: {
      type: Boolean,
      required: false,
      default: () => {
        return false as boolean;
      },
    },
    allowNavigation: {
      type: Boolean,
      required: false,
      default: () => {
        return true as boolean;
      },
    },
    updateInitialDate: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    arrangementsWithLicensePlate2: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      wizardProvider: wizardProvider,
      ready: false,
    };
  },
  async created() {
    let startdate: any = this.$store.getters.startDate;
    if (startdate) {
      startdate = moment(startdate);
    } else {
      startdate = moment();
    }

    if (process.env.VUE_APP_DATE_OFFSET) {
      startdate.add(parseInt(process.env.VUE_APP_DATE_OFFSET), 'days');
    }
    this.$store.commit('SET_START_DATE', startdate.format('YYYY-MM-DD'));
    this.$store.commit('SET_PETS_CONFIG', this.petsConfig);

    if (this.salesChannel) {
      this.$store.commit('SET_TOMMY_SALESCHANNEL', this.salesChannel);
    }

    if (document && this.theme) {
      document.body.classList.add(this.theme);
    }

    const token: any = '' + this.token;
    const split: any = token.split(':');

    let uri = window.location.href.split('?');
    let getVars = {} as any;
    if (uri.length == 2) {
      let vars = uri[1].split('&');
      let tmp = '';
      vars.forEach(function(v) {
        const tmp = v.split('=');
        if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
      });
    }
    const arrangementId: string | boolean = getVars['arrangement'] || false;

    this.$store.commit('SET_META', {
      usePin: this.usePin,
      token: token,
      user: split[0].toLowerCase(),
      hash: split[1],
      accommodationGroups: this.accommodationGroups || [],
      mapsKey: this.mapsKey || undefined,
      colorLoader: this.colorLoader || undefined,
      colorZoomBg: this.colorZoomBg || undefined,
      colorZoomFg: this.colorZoomFg || undefined,
      entrypoints: this.entrypoints,
      travelGroupLimits: Object.assign({}, this.travelGroupLimits),
      maxDuration: this.maxDuration,
      salesChannel: this.salesChannel,
      accommodationLabels: this.accommodationLabels,
      alternativeResults: this.alternativeResults,
      useMarkerClusterer: this.useMarkerClusterer,
      arrangementId: arrangementId,
      updateInitialDate: this.updateInitialDate,
      arrangementsWithLicensePlate2: this.arrangementsWithLicensePlate2 || undefined,
    });
    if (this.translations) {
      this.$store.commit('UPDATE_TRANSLATION_CONTEXT', this.translations);
    }
    if (false !== arrangementId) {
      this.$store.commit('SET_TOMMY_ARRANGEMENT_ID', arrangementId);
    }

    const travelGroupDefaultChoice: any = {};
    Object.keys(this.$store.getters.meta.travelGroupLimits || {}).forEach((key: string) => {
      const options: any = this.$store.getters.meta.travelGroupLimits[key];
      const getDefault: any = options.default || 0;
      if (getDefault > 0) {
        travelGroupDefaultChoice[key] = getDefault;
      }
    });

    if (Object.keys(travelGroupDefaultChoice).length > 0) {
      this.$store.commit('SET_TOMMY_TRAVELGROUP', travelGroupDefaultChoice);
    }

    const helper = new TommyHelper(this.$store);
    wizardProvider.setHelper(helper);
    await helper.setup();
    this.ready = true;
  },
  async mounted() {
    wizardProvider.init(this.$store);

    this.$app.provider = wizardProvider;
    this.$app.helper = wizardProvider.helper;
  },
  methods: {
    prev() {
      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('updateMountKey');
      this.$store.dispatch('previousStep');
    },
    quit() {
      this.$store.dispatch('restoreConfig');
      this.$store.dispatch('updateMountKey');
      this.$store.commit('SET_STEP_NUMBER', 1);
    },
  },
  computed: {
    ...mapGetters(['config', 'stepNumber', 'stepCount', 'locale']),
  },
});
