const tommyTranslations: any = {
  'timeout-title': {
    nl: 'Hallo, ben je er nog?',
    de: 'Hallo, sind Sie noch da?',
    fr: 'Bonjour, vous êtes toujours là ?',
    en: 'Hello, are you still there?',
  },
  'timeout-text': {
    nl: 'Je hebt deze zuil 5 minuten niet gebruikt. Over 30 seconden breken we je boeking af. Maak een keuze:',
    de:
      'Sie haben diese Spalte seit 5 Minuten nicht mehr benutzt. Wir werden Ihre Buchung in 30 Sekunden stornieren. Treffen Sie eine Wahl:',
    fr:
      "Vous n'avez pas utilisé cette rubrique depuis 5 minutes. Nous annulerons votre réservation dans 30 secondes. Faites un choix :",
    en: "You haven't used this terminal for 5 minutes. We'll cancel your booking in 30 seconds. Make a choice:",
  },
  'timeout-continue': {
    nl: 'Verder met boeken',
    de: 'Weiter zur Buchung',
    fr: 'Continuer avec les livres',
    en: 'Continue with booking',
  },
  'timeout-quit': {
    nl: 'Afbreken, naar startscherm',
    de: 'Abbrechen, zum Startbildschirm',
    fr: "Abandonner, pour démarrer l'écran",
    en: 'Return to start screen',
  },
  pets: {
    nl: 'Huisdieren',
    de: 'Haustiere',
    fr: 'Animaux',
    en: 'Pets',
  },
  'pets-title': {
    nl: 'Huisdieren',
    de: 'Haustiere',
    fr: 'Animaux',
    en: 'Pets',
  },
  title: {
    nl: 'Boeken',
    de: 'Buchen',
    fr: 'Reserver',
    en: 'Book',
  },
  close: {
    nl: 'Sluiten',
    de: 'schließen',
    fr: 'Fermer',
    en: 'Close',
  },
  'loading-please-wait': {
    nl: 'Even geduld aub ...',
    de: 'Laden, bitte warten ...',
    fr: 'Chargement, veuillez patienter ...',
    en: 'Loading, please wait ...',
  },
  'btn-next': {
    nl: 'Volgende',
    de: 'Nächste',
    fr: 'Suivant',
    en: 'Next',
  },
  'btn-complete': {
    nl: 'Boeking bevestigen',
    de: 'Buchung bestätigen',
    fr: 'Confirmation de la réservation',
    en: 'Confirm booking',
  },
  'intro-booking': {
    nl: 'Start boeking',
    de: 'Buchung beginnen',
    fr: 'Commencer la réservation',
    en: 'Start booking',
  },
  'intro-reservation': {
    nl: 'Ik heb gereserveerd',
    de: 'Ich habe eine Reservierung vorgenommen.',
    fr: "J'ai fait une réservation.",
    en: 'I made a reservation.',
  },
  until: {
    nl: 'tot',
    de: 'bis',
    fr: 'bis',
    en: 'until',
  },
  night: {
    nl: 'nacht',
    de: 'Nacht',
    fr: 'nuit',
    en: 'night',
  },
  nights: {
    nl: 'nachten',
    de: 'Nächte',
    fr: 'nuits',
    en: 'nights',
  },
  'number-of-nights': {
    nl: 'Aantal nachten',
    de: 'Anzahl der Nächte',
    fr: 'Nombre de nuits',
    en: 'Number of nights',
  },
  'check-details': {
    nl: 'Controle gegevens',
    de: 'Bitte prüfen Sie Ihre Buchung',
    fr: 'Données de contrôle',
    en: 'Control data',
  },
  'total-amount': {
    nl: 'Totaalbedrag',
    de: 'Gesamtbetrag',
    fr: 'Montant total',
    en: 'Total amount',
  },
  'night-available': {
    nl: 'nacht beschikbaar',
    de: 'Nacht verfügbar',
    fr: 'nuit disponible',
    en: 'night available',
  },
  'nights-available': {
    nl: 'nachten beschikbaar',
    de: 'Nächte verfügbar',
    fr: 'nuits disponibles',
    en: 'nights available',
  },
  'alternative-results': {
    nl: 'Alternatieve resultaten',
    de: 'Alternative Ergebnisse',
    fr: 'Résultats alternatifs',
    en: 'Alternative results',
  },
  'stay-cost': {
    nl: 'Verblijfskosten',
    de: 'Kosten für Unterkunft und Verpflegung',
    fr: "Frais d'hébergement",
    en: 'Accommodation costs',
  },
  'total-cost-exclusive': {
    nl: 'Totaal excl. BTW',
    de: 'Total exkl. MwSt.',
    fr: 'Total hors TVA',
    en: 'Total excl. VAT',
  },
  vat: {
    nl: 'BTW',
    de: 'MwSt',
    fr: 'TVA',
    en: 'VAT',
  },
  'payment-later': {
    nl: 'Betalen kan met mobiel bankieren of op een later tijdstip bij de receptie.',
    de: 'Die Zahlung kann per Mobile Banking oder zu einem späteren Zeitpunkt an der Rezeption erfolgen.',
    fr: 'Le paiement peut être effectué par la banque mobile ou à une heure ultérieure à la réception.',
    en: 'Payment can be made by mobile banking or at a later time at reception.',
  },
  arrival: {
    nl: 'Aankomst',
    de: 'Anreise',
    fr: 'Arrivée',
    en: 'Arrival',
  },
  departure: {
    nl: 'Vertrek',
    de: 'Abreise',
    fr: 'Départ',
    en: 'Departure',
  },
  'available-results': {
    nl: 'Beschikbaar',
    de: 'Verfügbar unter',
    fr: "Disponible à l'adresse suivante",
    en: 'Available at',
  },
  'stay-duration': {
    nl: 'Verblijfsduur',
    de: 'Dauer des Aufenthalts',
    fr: 'Durée du séjour',
    en: 'Length of stay',
  },
  'license-plate-vehicle': {
    nl: 'Kenteken voertuig',
    de: 'Fahrzeug-Kennzeichen',
    fr: "Numéro d'immatriculation du véhicule",
    en: 'Vehicle registration number',
  },
  firstname: {
    nl: 'Voornaam',
    de: 'Vorname',
    fr: 'Prénom',
    en: 'First name',
  },
  infix: {
    nl: 'Tussenvoegsel',
    de: 'Einfügen',
    fr: 'Insérer',
    en: 'Middle',
  },
  surname: {
    nl: 'Achternaam',
    de: 'Nachname',
    fr: 'Nom de famille',
    en: 'Last name',
  },
  email: {
    nl: 'E-mail adres',
    de: 'E-Mail-Adresse',
    fr: 'Adresse électronique',
    en: 'E-mail address',
  },
  birthdate: {
    nl: 'Geboortedatum',
    de: 'Geburtsdatum',
    fr: 'Date de naissance',
    en: 'Date of birth',
  },
  mobile: {
    nl: 'Mobiel',
    de: 'Mobil',
    fr: 'Mobile',
    en: 'Mobile',
  },
  telephone: {
    nl: 'Telefoon/mobiel',
    de: 'Telefon/Mobil',
    fr: 'Téléphone/Mobile',
    en: 'Telephone/Mobile',
  },
  'license-plate': {
    nl: 'Kenteken',
    de: 'Nummernschild',
    fr: "Plaque d'immatriculation",
    en: 'License plate',
  },
  'license-plate2': {
    nl: 'Kenteken 2',
    de: 'Nummernschild 2',
    fr: "Plaque d'immatriculation 2",
    en: 'License plate 2',
  },
  street: {
    nl: 'Straat',
    de: 'Straße',
    fr: 'Rue',
    en: 'Street',
  },
  housenumber: {
    nl: 'Huisnummer',
    de: 'Hausnummer',
    fr: 'Numéro de la maison',
    en: 'House number',
  },
  place: {
    nl: 'Plaats',
    de: 'Ort',
    fr: 'Ville natale',
    en: 'Town',
  },
  zipcode: {
    nl: 'Postcode',
    de: 'Postleitzahl',
    fr: 'Code postal',
    en: 'Zip code',
  },
  country: {
    nl: 'Land',
    de: 'Land',
    fr: 'Pays',
    en: 'Country',
  },
  'checkbox-no-car-or-camper': {
    nl: 'Ik kom zonder auto of camper',
    de: 'Ich komme ohne Auto oder Wohnmobil',
    fr: 'Je viens sans voiture ni camping-car',
    en: 'I come without a car or camper',
  },
  'results-less': {
    nl: 'Terug',
    de: 'Zurück',
    fr: 'retour',
    en: 'Back',
  },
  'results-more': {
    nl: 'Meer',
    de: 'Mehr',
    fr: 'En savoir plus',
    en: 'More',
  },
  'step-back': {
    nl: 'Stap terug',
    de: 'zurück',
    fr: 'Étape précédente',
    en: 'Previous step',
  },
  'nav-quit': {
    nl: 'Stoppen en opnieuw beginnen',
    de: 'Anhalten und neu starten',
    fr: 'Arrêter et recommencer',
    en: 'Stop and start again',
  },
  'nav-guide': {
    nl: 'Loop je tegen een probleem aan? Meld je dan bij de receptie of bel %phone_number% als de receptie is gesloten.',
    de:
      'Haben Sie ein Problem? Dann melden Sie sich bitte an der Rezeption oder rufen Sie %phone_number% an, wenn die Rezeption geschlossen ist.',
    fr: 'Vous avez un problème ? Alors signalez-le à la réception ou appelez le %phone_number% lorsque la réception est fermée.',
    en: 'Do you have a problem? Then report it to reception or call %phone_number% when reception is closed.',
  },
  'intro-text-1': {
    nl: `
      <h2>Boek een kampeer- of camperplaats</h2>
      <p>Je kunt hier een kampeer- of camperplaats boeken en direct inchecken. Betalen kan met mobiel bankieren of op een later tijdstip bij de receptie.</p>
      <p>Uitgebreide informatie over onze kampeer- en camper plaatsen vind je de receptie of check %website_url%</p>
    `,
    de: `
      <h2>Campingplatz oder Wohnmobilstellplatz buchen</h2>
      <p>Sie können hier einen Camping- oder Wohnmobilstellplatz buchen und direkt einchecken. Sie können mit Mobile Banking oder zu einem späteren Zeitpunkt an der Rezeption bezahlen.</p>
      <p>Umfassende Informationen über unsere Camping- und Wohnmobilstellplätze finden Sie an der Rezeption oder unter %website_url%</p>
    `,
    fr: `
      <h2>Réserver un emplacement de camping ou de camping-car</h2>
      <p>Vous pouvez réserver un emplacement de camping ou de camping-car ici et vous enregistrer directement. Vous pouvez payer avec le service bancaire mobile ou plus tard à la réception.</p>
      <p>Vous trouverez des informations complètes sur nos emplacements de camping et de caravane à la réception ou sur le site %website_url%.</p>
    `,
    en: `
      <h2>Book a camping- or motorhome pitch</h2>
      <p>You can book a camping- or motorhome pitch here and check in directly. You can pay with mobile banking or at a later time at the reception.</p>
      <p>Comprehensive information about our camping- and camper pitches can be found at the reception or check %website_url%</p>
    `,
  },
  'intro-text-2': {
    nl: `
      <h3>Accommodatie boeken</h3>
      <p>Boeken van accommodaties kan uitsluitend bij de receptie tijdens openingstijden of online via %website_url%.</p>
    `,
    de: `
    <h3> Unterkunft buchen</h3>
    <p>Die Buchung einer Unterkunft ist nur an der Rezeption während der Öffnungszeiten oder online unter %website_url% möglich.</p>
  `,
    fr: `
    <h3> Réserver un logement</h3>
    <p>La réservation d'un logement est possible uniquement à la réception pendant les heures d'ouverture ou en ligne sur %website_url%.</p>
  `,
    en: `
    <h3> Booking accommodation</h3>
    <p>Booking accommodation is only possible at the reception during opening hours or online at %website_url%.</p>
  `,
  },
  'reserved-text': {
    nl: `
      <p>Todo: ik heb reeds gereserveerd tekst.</p>
    `,
    de: `
    <p>Todo: Ich habe bereits Text gebucht.</p>
  `,
    fr: `
    <p>Todo : J'ai déjà réservé le texte.</p>
  `,
    en: `
    <p>Todo: I've already booked text.</p>
  `,
  },
  'accommodation-text-1': {
    nl: '',
    de: '',
    fr: '',
    en: '',
  },
  'travelgroup-title': {
    nl: 'Reisgezelschap',
    de: 'Reisegesellschaft',
    fr: 'Compagnie de voyage',
    en: 'Travel company',
  },
  'floorplan-title': {
    nl: 'Kies uw locatie',
    de: 'Wählen Sie Ihren Standort',
    fr: 'Choisissez votre lieu',
    en: 'Choose your location',
  },
  'floorplan-chosen-location': {
    nl: 'Geselecteerde locatie',
    de: 'Ausgewählter Standort',
    fr: 'Lieu sélectionné',
    en: 'Selected location',
  },
  'floorplan-choose-location': {
    nl: 'Selecteer een locatie op de kaart door op (afbeelding druppel) te klikken.',
    de: 'Wählen Sie Ihren Platz auf dem Parkplan, indem Sie auf dem Markierungssymbol klicken',
    fr: "Sélectionnez un emplacement sur le plan en cliquant sur (l'image du marqueur.) ",
    en: 'Select a location on the map by clicking the (marker icon).',
  },
  'travelgroup-too-many': {
    nl: '<p>Per accommodatie zijn maximaal %total_max_persons% personen toegestaan.',
    de: '<p>Per Unterkunft sind maximal %total_max_persons% Personen erlaubt.',
    fr: '<p>Par logement, un maximum de %total_max_persons% personnes sont autorisées.',
    en: '<p>Per accommodation a maximum of %total_max_persons% persons are allowed.',
  },
  'finish-text-1': {
    nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>De boeking is afgerond en je bent ingechecked op locatie <strong class="location">%accommodation_name% (%location_name%)</strong>. Je ontvangt binnen enkele minuten een bevestiging per mail. Betaal voor %payment_due_date% bij de receptie of met mobiel bankieren.</p>
        <p>Je ontvangt binnen enkele minuten een bevestiging per mail met betaallink. Je kunt ook betalen op de receptie.</p>
    `,
    de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Die Buchung ist abgeschlossen und Sie haben am Standort <strong class="location">%accommodation_name% (%location_name%)</strong> eingecheckt. Sie erhalten innerhalb weniger Minuten eine Bestätigung per E-Mail. Bezahlen Sie %payment_due_date% an der Rezeption oder mit Mobile Banking.</p>
        <p>Fahren Sie zur Schranke. Es öffnet sich automatisch.</p>
    `,
    fr: `
        <h2>Réservation effectuée avec succès</h2>
        <p>La réservation a été effectuée et vous vous êtes enregistré à l'emplacement <strong class="location">%accommodation_name% (%location_name%)</strong>. Vous recevrez une confirmation par courrier électronique dans les minutes qui suivent. Payez le %payment_due_date% à la réception ou avec le mobile banking.</p>
        <p>Venir à la barrière. Il s'ouvre automatiquement.</p>
    `,
    en: `
        <h2>Booking successfully completed</h2>
        <p>The booking has been completed and you have checked-in at location <strong class="location">%accommodation_name% (%location_name%)</strong>. You will receive a confirmation by email within a few minutes. Pay on %payment_due_date% at the reception or with mobile banking.</p>
        <p>Drive to the barrier. It opens automatically.</p>
    `,
  },
  'finish-text-2': {
    nl: '<h1 class="xl">Jouw vakantie op %park_name% is begonnen!</h1>',
    de: '<h1 class="xl">Ihr Urlaub auf %park_name% hat begonnen!</h1>',
    fr: '<h1 class="xl">Vos vacances sur %park_name% ont commencé!</h1>',
    en: '<h1 class="xl">Your holiday on %park_name% has begun!</h1>',
  },
  'required-field-error': {
    nl: 'Vul je %field% in',
    de: 'Füllen Sie Ihr %field%',
    fr: 'Remplissez votre %field%.',
    en: 'Fill in your %field%',
  },
  'duration-title': {
    nl: 'Verblijfsduur',
    de: 'Dauer des Aufenthalts',
    fr: 'Durée du séjour',
    en: 'Length of stay',
  },
  'accommodation-title': {
    nl: 'Kies een type',
    de: 'Wählen Sie einen Typ',
    fr: 'Choisissez un type',
    en: 'Choose a type',
  },
  'extras-title': {
    nl: "Extra's",
    de: 'Extras',
    fr: 'Extras',
    en: 'Extras',
  },
  'extras-other': {
    nl: 'Overige',
    de: 'Andere',
    fr: 'Autres',
    en: 'Additional',
  },
  'extras-couponcode': {
    nl: 'Actiecode',
    de: 'Angebots-Code',
    fr: "Code de l'offre",
    en: 'Offer code',
  },
  'extras-couponcode-required': {
    nl: '(verplicht)',
    de: '(obligatorisch)',
    fr: '(obligatoire)',
    en: '(mandatory)',
  },
  'receipt-title': {
    nl: 'Kassabon',
    de: 'Quittung',
    fr: 'Reçu',
    en: 'Receipt',
  },
  'confirm-title': {
    nl: 'bevestigen',
    de: 'bestätigen',
    fr: 'confirmer',
    en: 'confirm',
  },
  'personaldata-title': {
    nl: 'Persoonsgegevens',
    de: 'Persönliche Daten',
    fr: 'Données à caractère personnel',
    en: 'Personal information',
  },
  'address-title': {
    nl: 'Adresgegevens',
    de: 'Angaben zur Adresse',
    fr: 'Informations sur les adresses',
    en: 'Address details',
  },
  salution: {
    nl: 'Aanhef',
    de: 'Anrede',
    fr: 'Salutation',
    en: 'Salutation',
  },
  'salution-malefemale': {
    nl: 'Meneer / Mevrouw',
    de: 'Herr / Frau',
    fr: 'Monsieur / Madame',
    en: 'Mr / Mrs',
  },
  'salution-male': {
    nl: 'Meneer',
    de: 'Herr',
    fr: 'Monsieur',
    en: 'Mister',
  },
  'salution-female': {
    nl: 'Mevrouw',
    de: 'Frau',
    fr: 'Madame',
    en: 'Mrs',
  },
  'salution-family': {
    nl: 'Familie',
    de: 'Familie',
    fr: 'Famille',
    en: 'Family',
  },
  'required-notice': {
    nl: 'Niet alle velden zijn goed ingevuld.',
    de: 'Nicht alle Felder sind korrekt ausgefüllt.',
    fr: 'Tous les champs ne sont pas remplis correctement.',
    en: 'Not all fields are filled in correctly.',
  },
  'pin-title': {
    nl: 'Pinnen',
    de: 'Zahlen',
    fr: 'Payer',
    en: 'Pay',
  },
  'pin-description': {
    nl: `
      <p>Binnen enkele seconden kunt u hieronder betalen met uw betaalpas</p>
      <p>U heeft 30 seconden om de betaling te voldoen, lukt dit niet?</p>
      <p>Wij versturen u nog altijd een betaallink per mail, zodat u alsnog de betaling kan voldoen</p>
    `,
    de: `
      <p>In wenigen Sekunden können Sie unten mit Ihrer Zahlungskarte bezahlen</p>
      <p>Sie haben 30 Sekunden, um die Zahlung zu tätigen, hat es nicht geklappt? </p>
      <p>Wir senden Ihnen nach wie vor einen Zahlungslink per E-Mail zu, so dass Sie die Zahlung noch vornehmen können</p><p>In wenigen Sekunden können Sie unten mit Ihrer Zahlungskarte bezahlen</p>.
    `,
    fr: `
      <p>En quelques secondes, vous pouvez payer ci-dessous avec votre carte de paiement</p>
      <p>Vous avez 30 secondes pour effectuer le paiement, ça n'a pas marché ? </p>
      <p>Nous vous envoyons toujours un lien de paiement par e-mail, de sorte que vous pouvez toujours effectuer le paiement</p><p>En quelques secondes, vous pouvez payer ci-dessous avec votre carte de paiement</p>.
    `,
    en: `
      <p>In a few seconds you can pay below with your payment card</p>
      <p>You have 30 seconds to make the payment, it didn't work? </p>
      <p>We still send you a payment link by email, so that you can still make the payment</p><p>Within a few seconds you can pay below with your payment card</p>
    `,
  },
  'pin-button': {
    nl: 'Voltooi boeking',
    de: 'Fertigstellen',
    fr: 'Terminer',
    en: 'Finish',
  },
  'reductionpass-title': {
    nl: 'Voordeelkaarten',
    de: 'Rabattkarten ',
    en: 'Discount cards',
    // todo: translate
    fr: '',
  },
  'reductionpass-none': {
    nl: 'Geen voordeelkaart',
    de: 'Keine Rabattkarte',
    en: 'No discount card',
    fr: 'Pas de carte de réduction',
  },
  'price-no-reductionpass': {
    nl: 'Prijs zonder voordeelkaart',
    de: 'Preis ohne Rabattkarte',
    fr: 'Prix sans carte de réduction',
    en: 'Price without discount card',
  },
  before: {
    nl: 'voor',
    de: 'für',
    fr: 'pour',
    en: 'for',
  },
  after: {
    nl: 'na',
    de: 'nach',
    fr: 'après',
    en: 'after',
  },
};

module.exports = tommyTranslations;
