import axios, { AxiosInstance } from 'axios';
import moment from 'moment';
import qs from 'qs';
import { Store } from 'vuex';
import AvailabilityModel from './Widget/model/AvailabilityModel';
import ReceiptModel from './Widget/model/ReceiptModel';

export class TommyClient {
  private clientApi: AxiosInstance;
  private clientWww: AxiosInstance;
  private cache: any = {};

  readonly API_BASE_URL = process.env.VUE_APP_TOMMY_API_BASE_URL;
  readonly API_WWW_BASE_URL = process.env.VUE_APP_TOMMY_WWW_BASE_URL;

  constructor(private store: Store<any>, private token: string) {
    this.clientApi = axios.create({
      baseURL: this.API_BASE_URL,
    });
    this.clientWww = axios.create({
      baseURL: this.API_WWW_BASE_URL,
    });

    if (this.token) {
      const token: any = this.token;

      this.clientApi.interceptors.request.use(function(config) {
        config.headers.authentication = token;

        return config;
      });
      this.clientWww.interceptors.request.use(function(config) {
        config.headers.authentication = token;

        return config;
      });
    }
  }

  async load() {
    if (!this.store || !this.store.getters['apiContext']) {
      return;
    }

    return await this.getWidgetBoeken().then(async (widgetResponse: any) => {
      delete widgetResponse.html;

      this.store.commit('SET_TOMMY_API_WIDGET_BOEKEN', widgetResponse.data);
      const travelGroupLimits: any = this.parseTravelGroupLimits(widgetResponse.data);
      this.store.commit('SET_TOMMY_API_TRAVELGROUP_LIMITS', travelGroupLimits);

      if (false !== travelGroupLimits.global.max) {
        this.store.commit('UPDATE_TRANSLATION_CONTEXT', {
          total_max_persons: travelGroupLimits.global.max,
        });
      }
      this.store.commit('SET_TOMMY_API_FLOORPLAN', widgetResponse.data.variables.instellingen.plattegrond || null);

      if (widgetResponse.data.variables.verkoopkanaal) {
        this.store.commit('SET_TOMMY_SALESCHANNEL', widgetResponse.data.variables.verkoopkanaal);
      }

      this.store.commit('SET_TOMMY_API_SETTINGS', widgetResponse.data.variables.instellingen);
      this.store.commit('SET_TOMMY_API_PERSONDETAILS', widgetResponse.data.variables.persoonsgegevens);

      return await this.getMetadata().then((metaResponse: any) => {
        const metadata: any = metaResponse.data.data[0].metadata;
        this.store.commit('SET_TOMMY_API_META', metadata);
        this.store.commit('SET_TOMMY_API_ACCOMMODATIONGROUPS', metadata.accommodations);
        this.store.commit('SET_TOMMY_API_PERSONCATEGORIES', metadata.personCategories);
        this.store.commit('SET_TOMMY_API_LANGUAGES', metadata.languages);

        // not needed at this stage, refresh when available.
        this.store.commit('SET_TOMMY_API_ARTICLES', []);
        this.store.commit('SET_TOMMY_API_CHARACTERISTICS', []);

        // wait for rest to be loaded.
        this.store.commit('SET_TOMMY_API_ACCOMMODATIONS', widgetResponse.data.variables.accommodaties);

        let defaultLanguage: any = false as any;
        metadata.languages.forEach((language: any) => {
          if (true === language.default) {
            defaultLanguage = language;
          }
        });

        this.store.dispatch('updateLocale', defaultLanguage ? defaultLanguage.code : 'nl');
      });
    });
  }

  parseTravelGroupLimits(apiData: any): any {
    const limits: any = {
      global: {
        min: 0,
        max: false,
      },
      accommodations: {},
      travelGroups: {},
    };

    apiData.variables.accommodaties.forEach((widgetAccommodation: any) => {
      if (!limits.accommodations[widgetAccommodation.id]) {
        limits.accommodations[widgetAccommodation.id] = {
          min: widgetAccommodation.minPersonen || false,
          max: widgetAccommodation.maxPersonen || false,
          travelGroups: {},
        };
      }

      widgetAccommodation.persoonscategorien.forEach((widgetPersonCategory: any) => {
        limits.accommodations[widgetAccommodation.id].travelGroups[widgetPersonCategory.id] = {
          min: widgetPersonCategory.minPersonen || false,
          max: widgetPersonCategory.maxPersonen || false,
          total: widgetPersonCategory.totaal || false,
        };

        // todo: limits by tommy API.
        // if (
        //   !limits.travelGroups[widgetPersonCategory.id] ||
        //   widgetPersonCategory.maxPersonen > limits.travelGroups[widgetPersonCategory.id]
        // ) {
        //   limits.travelGroups[widgetPersonCategory.id] = widgetPersonCategory.maxPersonen;
        // }
      });

      Object.keys(limits.accommodations).forEach((key: any) => {
        const limitAccommodation: any = limits.accommodations[key];

        if (false === limits.global.max || limitAccommodation.max > limits.global.max) {
          // todo: global limits by tommy API.
          // limits.global.max = limitAccommodation.max;
        }
      });
    });

    // todo: only override by config instead of ignoring api limits ?
    const configLimits: any = this.store.getters.meta.travelGroupLimits || {};
    const globalLimit: any = configLimits.global || false;
    delete configLimits.global;
    limits.travelGroups = configLimits;
    limits.global = globalLimit;

    return limits;
  }

  parseParams(context: any, keymap?: any) {
    if (!keymap) {
      keymap = {};
    }

    const paramKeys: any = {
      ...{
        arrayTravelGroup: false,
        jsonTravelGroup: false,
        arrayArticles: false,
        durationOffset: false,
        language: 'language',
        salesChannel: 'verkoopkanaal',
        accommodationGroup: 'group',
        persons: 'persoonscategorie',
        arrangement: false,
        accommodation: false,
        location: false,
        total: false,
        startdate: false,
        enddate: false,
        period: false,
        articles: false,
        couponCode: false,
        priceTrigger: false,
      },
      ...keymap,
    };

    const params: any = {};

    // only use accommodation group parameter in api request when a single group is requested (otherwise we filter the results on response, since we can only gif one group as a parameter value).
    if (context.accommodationGroups && false !== paramKeys.accommodationGroup && 1 === context.accommodationGroups.length) {
      params[paramKeys.accommodationGroup] = context.accommodationGroups[0];
    }

    if (context.language && false !== paramKeys.language) {
      params[paramKeys.language] = context.language;
    }

    const duration = null == paramKeys.durationOffset ? context.duration : context.duration + paramKeys.durationOffset;

    const dteStart: any = moment(this.store.getters.startDate);
    const startString: string = dteStart.format('YYYY-MM-DD');

    let dteEnd: any = moment(this.store.getters.endDate);
    let endString: string;
    if (dteEnd) {
      endString = dteEnd.format('YYYY-MM-DD');
    } else {
      dteEnd = moment(this.store.getters.startDate).add(duration, 'days');
      endString = dteEnd.format('YYYY-MM-DD');
    }

    if (context.arrangement && false !== paramKeys.arrangement) {
      params[paramKeys.arrangement] = context.arrangement;
    }

    if (context.startdate && false !== paramKeys.startdate) {
      params[paramKeys.startdate] = startString;
    }

    if (context.enddate && false !== paramKeys.enddate) {
      params[paramKeys.enddate] = endString;
    }

    if (false !== paramKeys.period) {
      params[paramKeys.period] = '' + startString + ' ' + endString;
    }

    if (false !== paramKeys.priceTrigger) {
      if (this.store.getters.priceTrigger) {
        params[paramKeys.priceTrigger] = '' + this.store.getters.priceTrigger;
      }
    }

    if (false !== paramKeys.articles) {
      const petsConfig: any = this.store.getters.petsConfig || {};
      const petsArticleIds: any = (petsConfig.articles || []).map((val: any) => parseInt(val));

      if (paramKeys.arrayArticles) {
        params[paramKeys.articles] = [];
      }

      let petLoopCount: number = 0;
      this.store.getters.apiArticles.forEach((apiArticle: any) => {
        const articleId: number = parseInt(apiArticle.id);

        // special case to fill pet counts in api params, based on account/settings.
        if (petsArticleIds.length && petsArticleIds.includes(articleId)) {
          // count to fill per article number on API request
          // if we didn't select any pets, ignore
          // if we did select it and we only have one article ID, use the total count in one article ID
          // if we did select it and we have multiple article ID's, fill 1 per article ID (for now, can only occur as 2 article id's with a maximum of 2 pets)
          const articleIdCount: number =
            this.store.getters.petsCount > 0 ? (petsArticleIds.length <= 1 ? this.store.getters.petsCount : 1) : 0;

          // check if we need to fill any pet article id.
          if (articleIdCount > 0 && petLoopCount < this.store.getters.petsCount) {
            if (paramKeys.arrayArticles) {
              params[paramKeys.articles].push({ id: articleId, aantal: articleIdCount });
            } else {
              params[paramKeys.articles + '[' + articleId + ']'] = articleIdCount;
            }

            petLoopCount += articleIdCount;
          }
        } else if (context.extras && context.extras.articles && context.extras.articles[articleId]) {
          // else use article value from extras step

          if (paramKeys.arrayArticles) {
            params[paramKeys.articles].push({ id: articleId, aantal: context.extras.articles[articleId] });
          } else {
            params[paramKeys.articles + '[' + articleId + ']'] = context.extras.articles[articleId];
          }
        }
      });

      /*
      todo: remove debug

      if (this.store.getters.petsCount > 0 && petLoopCount !== this.store.getters.petsCount) {
        console.warn('this.store.getters.apiArticles', this.store.getters.apiArticles);
        console.warn('petsArticleIds', petsArticleIds);
        console.warn('petLoopCount', petLoopCount);
        console.warn('this.store.getters.petsCount', this.store.getters.petsCount);
        console.warn('params', params);

        throw 'Failed to store pet articles.';
      }
      */

      if (paramKeys.arrayArticles) {
        params[paramKeys.articles] = JSON.stringify(params[paramKeys.articles]);
      }
    }

    if (context.salesChannel && false !== paramKeys.salesChannel) {
      params[paramKeys.salesChannel] = context.salesChannel;
    }

    if (context.couponCode && false !== paramKeys.couponCode) {
      params[paramKeys.couponCode] = context.couponCode;
    }

    if (context.accommodation && false !== paramKeys.accommodation) {
      params[paramKeys.accommodation] = context.accommodation;
    }
    if (context.location && false !== paramKeys.location) {
      params[paramKeys.location] = context.location;
    }

    if (context.travelGroup && false !== paramKeys.persons) {
      let total: number = 0;
      if (Object.keys(context.travelGroup).length > 0) {
        if (paramKeys.arrayTravelGroup === true) {
          params[paramKeys.persons] = [];
        } else if (paramKeys.jsonTravelGroup === true) {
          params[paramKeys.persons] = {};
        }

        Object.keys(context.travelGroup).forEach((key: any) => {
          const value: number = parseInt(context.travelGroup[key]);

          if (paramKeys.arrayTravelGroup === true) {
            params[paramKeys.persons].push({ id: key, aantal: value });
          } else if (paramKeys.jsonTravelGroup === true) {
            params[paramKeys.persons][key] = value;
          } else {
            params[paramKeys.persons + '[' + key + ']'] = value;
          }
          total += value;
        });

        if (paramKeys.arrayTravelGroup === true) {
          params[paramKeys.persons] = JSON.stringify(params[paramKeys.persons]);
        }

        if (false !== paramKeys.total) {
          params[paramKeys.total] = total;
        }
      }
    }

    return params;
  }

  async request(client: string = 'api', method: string = 'GET', endpoint: string, options: any, custom?: any) {
    const EXPIRATION = process.env.NODE_ENV === 'production' ? 300 : 30;
    const endTime: any = new Date();

    // cache cleanup expired keys
    Object.keys(this.cache).forEach((tmpCacheKey: string) => {
      const cached = this.cache[tmpCacheKey];
      const diff = (endTime.getTime() - cached.expires.getTime()) / 1000;

      if (diff > EXPIRATION) {
        delete this.cache[tmpCacheKey];
        console.debug('Invalidate response from cache', endpoint, tmpCacheKey, diff, Object.keys(this.cache).length);
      }
    });

    // create new cache key
    const cacheKey: string = JSON.stringify({
      method,
      endpoint,
      params: JSON.stringify(options.params || {}),
    });

    // restore from cache if available
    if ('get' === method.toLowerCase() && true === Object.keys(this.cache).includes(cacheKey)) {
      const cached = this.cache[cacheKey];
      const diff = (endTime.getTime() - cached.expires.getTime()) / 1000;
      console.debug('Restoring response from cache', endpoint, cacheKey, EXPIRATION - diff);

      return cached.data;
    }

    // load new response
    if (custom) {
      if ('get' === method.toLowerCase()) {
        options.params = Object.assign({}, { ...options.params, ...custom });
      } else {
        options.data = Object.assign({}, { ...options.data, ...custom });
      }
    }

    let postData: URLSearchParams | any = {};
    if ('post' === method.toLowerCase()) {
      postData = qs.stringify(options.data || {});

      if (!options.headers) {
        options.headers = {};
      }

      options.headers['content-type'] = 'application/x-www-form-urlencoded';
    }

    let data: any = {};
    if ('api' === client) {
      data = await this.clientApi({
        method: method.toLowerCase() as any,
        url: endpoint,
        params: options.params || {},
        data: postData,
        headers: options.headers || {},
      });
    } else {
      data = await this.clientWww({
        method: method as any,
        url: endpoint,
        params: options.params || {},
        data: postData,
        headers: options.headers || {},
      });
    }

    // cache response if cacheable
    if ('get' === method.toLowerCase()) {
      this.cache[cacheKey] = {
        data: data,
        expires: new Date(),
      };
    }

    return data;
  }

  getMetadata() {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      salesChannel: 'salesChannel',
    });

    return this.request('www', 'GET', 'widget/metadata', { params });
  }

  getWidgetBoeken() {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      accommodation: 'accommodatie',
      arrangement: 'arrangement',
    });

    const response = this.request('api', 'GET', 'widget/boeken', { params });

    return response.then((response: any) => {
      if (response.data.set.accommodatie) {
        this.store.commit('SET_TOMMY_ACCOMMODATION', response.data.set.accommodatie);
      }
      if (response.data.set.begindatum) {
        const beginDate = response.data.set.begindatum;
        const endDate = response.data.set.einddatum;

        const beginDateString = [beginDate.substr(0, 4), beginDate.substr(4, 2), beginDate.substr(6, 2)].join('-');
        const endDateString = [endDate.substr(0, 4), endDate.substr(4, 2), endDate.substr(6, 2)].join('-');

        this.store.commit('SET_START_DATE', beginDateString);
        this.store.commit('SET_END_DATE', endDateString);

        this.store.commit('SET_TOMMY_PERIOD', { start: beginDateString, end: endDateString });
      }

      return response;
    });
  }

  getAccommodations() {
    const params: any = this.parseParams(this.store.getters['apiContext']);

    return this.request('api', 'GET', 'accommodatie', { params });
  }

  getCalenderAvailability() {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      jsonTravelGroup: false,
      accommodation: 'accommodatie',
      salesChannel: 'verkoopkanaal',
      persons: 'persoonscategorie',
      startdate: 'begindatum',
      enddate: 'einddatum',
    });

    return this.request('api', 'GET', 'kalender/beschikbaarheid', { params });
  }

  getCalender() {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      persons: false,
      accommodation: 'accommodatie',
      salesChannel: 'verkoopkanaal',
      startdate: 'begindatum',
      enddate: 'einddatum',
    });

    const dteStart: any = moment(params.begindatum);
    params['datum'] = dteStart.format('MM-YYYY');
    params['kalenderAantal'] = '2';

    return this.request('api', 'GET', 'kalender', { params });
  }

  getArticles() {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      startdate: 'begindatum',
      enddate: 'einddatum',
      total: 'totaalPersonen',
      accommodation: 'accommodatie',
    });

    return this.request('api', 'GET', 'artikelen', { params });
  }

  getCharacteristics() {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      total: 'totaalPersonen',
      accommodation: 'accommodatie',
    });

    return this.request('api', 'GET', 'kenmerk', { params });
  }

  getPriceAndAvailability(durationOffset?: number): Promise<AvailabilityModel> {
    const params: any = this.parseParams(this.store.getters['apiContext'], {
      jsonTravelGroup: true,
      salesChannel: 'salesChannel',
      persons: 'persons',
      total: false,
      period: 'period',
    });

    const apiContext: any = this.store.getters['apiContext'];
    const duration = null == durationOffset ? apiContext.duration : apiContext.duration + durationOffset;

    const dteStart: any = moment(this.store.getters.startDate);
    const startString: string = dteStart.format('YYYY-MM-DD');
    const dteEnd: any = moment(this.store.getters.startDate).add(duration, 'days');
    const endString: string = dteEnd.format('YYYY-MM-DD');

    params.period = '' + startString + ' ' + endString;

    const accommodationGroups: any = apiContext.accommodationGroups || null;

    return this.request('www', 'GET', 'widget/price-and-availability', { params }).then((response: any) => {
      // if a accommodation group filter is set, filter results by whitelist.
      if (null !== accommodationGroups && accommodationGroups.length > 0) {
        const apiAccommodations: any = this.store.getters.apiAccommodations;
        const accommodationsData: any = [];

        response.data.data[0].accommodations.forEach((accommodationItem: any) => {
          const accommodationId: any = parseInt(accommodationItem.accommodation);

          let allowed: boolean = false;
          let apiAccommodation: any = false;

          // lookup accommodation object to get group id.
          apiAccommodations.forEach((tmpApiAccommodation: any) => {
            if (parseInt(tmpApiAccommodation.id) === accommodationId) {
              apiAccommodation = tmpApiAccommodation;

              // check if matching apiAccommodation object's "accommodatiegroep_id" matches the whitelist.
              if (accommodationGroups.includes(parseInt(apiAccommodation.accommodatiegroep_id))) {
                accommodationsData.push(accommodationItem);
                allowed = true;
              }
            }
          });

          if (!allowed && apiAccommodation) {
            console.debug(
              'exclude "' + apiAccommodation.naam + '" by group ids',
              '"' + apiAccommodation.accommodatiegroep_id + '" not in (' + accommodationGroups.join(', ') + ')',
            );
          }
        });

        // override new result set.
        response.data.data[0].accommodations = accommodationsData;
      }

      return new AvailabilityModel({ ...params, ...{ duration: duration } }, response.data.data[0] || {});
    });
  }

  getReceipt(durationOffset?: number): Promise<any> {
    const apiContext: any = this.store.getters['apiContext'];
    const duration = null == durationOffset ? apiContext.duration : apiContext.duration + durationOffset;

    const params: any = this.parseParams(this.store.getters['apiContext'], {
      jsonTravelGroup: false,
      durationOffset: durationOffset,
      salesChannel: 'verkoopkanaal',
      persons: 'persoonscategorie',
      total: 'totaalPersonen',
      articles: 'artikelen',
      startdate: 'begindatum',
      enddate: 'einddatum',
      accommodation: 'accommodatie',
      couponCode: 'coupon_code',
      priceTrigger: 'prijstrigger',
    });

    return this.request('api', 'GET', 'boeking', { params }).then((response: any) => {
      return new ReceiptModel({ ...params, ...{ duration: duration } }, response.data.data || {});
    });
  }

  createBooking(): Promise<any> {
    const choices: any = this.store.getters['choices'];
    const personal: any = choices.personaldata.data || {};

    const data: any = this.parseParams(this.store.getters['apiContext'], {
      arrayTravelGroup: true,
      arrayArticles: true,
      startdate: 'aankomstdatum',
      enddate: 'vertrekdatum',
      salesChannel: 'verkoopkanaal',
      persons: 'persoonscategorien',
      accommodation: 'accommodatie',
      location: 'locatie',
      couponCode: 'coupon_code',
      articles: 'artikelen',
      priceTrigger: 'prijstrigger',
    });

    const naw: any = {
      aanhef: personal.aanhef || 'm',
      bedrijfsnaam: '',
      voornaam: personal.voornaam,
      tussenvoegsel: personal.tussenvoegsel,
      achternaam: personal.achternaam,
      telefoon: personal.telefoon,
      email: personal.email,
      kenteken: personal.kenteken,
      straat: personal.straat,
      huisnummer: personal.huisnummer,
      geboortedatum: personal.geboortedatum || undefined,
      postcode: personal.postcode,
      plaats: personal.plaats,
      land: personal.land,
    };

    // append custom fields and their values.
    Object.keys(personal).forEach((personalKey: string) => {
      if ((-1 !== personalKey.indexOf('custom_') || 'kenteken2' === personalKey) && '' !== '' + personal[personalKey]) {
        naw[personalKey] = personal[personalKey];
      }
    });

    const mappedNaw: any = [];
    Object.keys(naw).forEach((key: any) => {
      mappedNaw.push({ id: key, value: naw[key] || '' });
    });

    return this.request(
      'www',
      'POST',
      'boeking/reserveren',
      { data: data },
      {
        apikey: this.token.split(':')[1],
        voorkeur: 'on',
        naw: JSON.stringify(mappedNaw),
        pay: false,
        ideal: false,
      },
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => error);
  }

  checkZipCode(data: any): Promise<any> {
    console.warn('data', data);

    return this.request(
      'api',
      'GET',
      'postcode',
      { params: data },
      {
        apikey: this.token.split(':')[1],
      },
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => error);
  }
}
