
import Vue from 'vue';
import { wizardProvider, TommyHelper } from '@/wizard';
import NumberToggle from '@/components/NumberToggle.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: {
    NumberToggle,
  },
  data(): any {
    return {
      wizardProvider: wizardProvider,
      helper: wizardProvider.helper as TommyHelper,
      ready: false,
      couponCode: null as any,
      values: {
        articles: {},
        characteristics: {},
      } as any,
    };
  },
  async mounted() {
    this.articlesShown.forEach((useArticle: any) => {
      Vue.set(this.values.articles, '' + useArticle.id, 0);
    });

    // todo: support characteristics multipleWaarden == true
    this.apiCharacteristics.forEach((apiCharacteristic: any) => {
      let defaultValue: any = '';
      if (apiCharacteristic.waarden && apiCharacteristic.waarden.length) {
        defaultValue = apiCharacteristic.waarden[0].id;
      }

      Vue.set(this.values.characteristics, '' + apiCharacteristic.id, defaultValue);
    });

    this.ready = true;
  },
  methods: {
    triggerClick: function() {
      this.$emit('choose', { data: this.values });
    },
    updateCouponCode: function() {
      this.$store.commit('SET_TOMMY_COUPON_CODE', this.couponCode);
    },
  },
  computed: {
    couponCodeRequired(): boolean {
      return true === this.apiSettings.actiecodeVerplicht;
    },
    allowedToContinue(): boolean {
      if (!this.couponCodeRequired) {
        return true;
      }

      return null !== this.couponCode && '' !== '' + this.couponCode;
    },
    ...mapGetters(['articlesShown', 'apiCharacteristics', 'apiSettings', 'petsConfig']),
  },
});
