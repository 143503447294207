import Vue from 'vue';
import Vuex from 'vuex';
import { StepInterface } from '@/model';

Vue.use(Vuex);

let loaderTimeout: any = false;

export const state = () => {
  return {
    locale: 'nl',
    step: null as any,
    stepNumber: 1,
    skippedSteps: [] as any,
    config: false as any,
    originalConfig: false as any,
    choices: {} as any,
    meta: {} as any,
    notifyMessage: false as any,
    notifyVisible: false,
    translationContext: {},
    loading: true,
    mountKey: 1,
  };
};

export const mutations = {
  SET_LOCALE(state: any, locale: string) {
    state.locale = locale;
  },
  SET_STEP(state: any, step: number) {
    state.step = step;
  },
  ADD_SKIPPED_STEP(state: any, stepNumber: number) {
    state.skippedSteps.push(stepNumber);
  },
  SET_STEP_NUMBER(state: any, stepNumber: number) {
    // cleanup made choices when navigating backwards.
    if (stepNumber < state.stepNumber) {
      state.config.steps.forEach((step: StepInterface, index: number) => {
        if (index + 1 >= stepNumber) {
          Vue.delete(state.choices, step.code);
        }
      });
    }

    state.stepNumber = stepNumber;
  },
  SET_CONFIG(state: any, config: any) {
    state.config = config;
  },
  SET_ORIGINAL_CONFIG(state: any, originalConfig: any) {
    state.originalConfig = Object.assign({}, originalConfig);
  },
  RESET_CONFIG(state: any) {
    state.config = state.originalConfig;
  },
  SET_CHOICE(state: any, options: any) {
    const stepNumber: number = options.stepNumber;
    const data: any = options.data;

    Vue.set(state.choices, state.config.steps[stepNumber - 1].code, data);
  },
  SET_CHOICES(state: any, choices: any) {
    state.choices = choices;
  },
  SET_META(state: any, meta: any) {
    state.meta = {
      ...state.meta,
      ...meta,
    };
  },
  SET_NOTIFY_MESSAGE(state: any, message: any) {
    state.notifyMessage = message;
  },
  SET_NOTIFY_VISIBLE(state: any, visibility: boolean) {
    state.notifyVisible = visibility;
  },
  UPDATE_TRANSLATION_CONTEXT(state: any, contextItems: any) {
    Object.keys(contextItems).forEach((key: any) => {
      state.translationContext[key] = contextItems[key];
    });
  },
  ADD_STEP(state: any, step: StepInterface) {
    state.config.steps.push(step);
  },
  UPDATE_MOUNT_KEY(state: any, mountKey: any) {
    state.mountKey = mountKey;
  },
  SET_LOADING(state: any, isLoading: boolean) {
    state.loading = isLoading;
  },
};

export const getters = {
  locale: (state: any) => {
    return state.locale;
  },
  step: (state: any) => {
    return state.step;
  },
  stepNumber: (state: any) => {
    return state.stepNumber;
  },
  stepCount: (state: any) => {
    if (!state.config || !state.config.steps) {
      return 0;
    }

    return state.config.steps.length;
  },
  config: (state: any) => {
    return state.config;
  },
  choices: (state: any) => {
    return state.choices;
  },
  meta: (state: any) => {
    return state.meta;
  },
  notifyMessage: (state: any) => {
    return state.notifyMessage;
  },
  notifyVisible: (state: any) => {
    return state.notifyVisible;
  },
  translationContext: (state: any) => {
    return state.translationContext;
  },
  loading: (state: any) => {
    return state.loading;
  },
  mountKey: (state: any) => {
    return state.mountKey;
  },
};

export const actions = {
  updateMountKey(context: any) {
    context.commit('UPDATE_MOUNT_KEY', context.state.mountKey + 1);
  },
  showPopup(context: any, payload: any) {
    context.commit('SET_NOTIFY_MESSAGE', payload);
    context.commit('SET_NOTIFY_VISIBLE', true);
  },
  removeStepsAfter(context: any, payload: any) {
    const config: any = context.state.config;
    const newSteps: StepInterface[] = [];
    let found: boolean = false;

    Object.keys(config.steps).forEach((key: any) => {
      const step: StepInterface = config.steps[key];
      if (!found) {
        newSteps.push(step);
      }

      if (payload.code == step.code) {
        found = true;
      }
    });

    config.steps = newSteps;

    context.commit('SET_CONFIG', config);
  },
  restoreConfig(context: any) {
    const originalConfig: any = context.state.originalConfig;

    context.commit('SET_CONFIG', originalConfig);
  },
  async startLoader(context: any) {
    if (true === context.state.loading) {
      return;
    }

    clearTimeout(loaderTimeout);

    return (loaderTimeout = setTimeout(() => {
      context.commit('SET_LOADING', true);
    }, 100));
  },
  stopLoader(context: any) {
    clearTimeout(loaderTimeout);
    if (false === context.state.loading) {
      return;
    }

    context.commit('SET_LOADING', false);
  },
  previousStep(context: any, payload: any) {
    let value: number = context.state.stepNumber - 1;
    while (context.state.skippedSteps.includes(value) && value > 1) {
      --value;
    }

    context.commit('SET_STEP_NUMBER', value);
  },
};
export const modules = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
});
