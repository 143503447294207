import { StepInterface, ButtonInterface, ConditionInterface } from '@/model';
import { HelperInterface } from '@/model/helper.interface';
import { Store } from 'vuex';

export class WizardProvider {
  public helper: HelperInterface;
  public store: Store<any>;

  getConfig() {
    return this.helper.getConfig();
  }

  translate(key: any, context?: any, options?: any) {
    if (!this.helper) {
      return key;
    }

    return this.helper.translate(key, context, options);
  }

  buildUrl(choices: any) {
    return this.helper.buildUrl(this.mapChoices(choices), choices);
  }

  mapChoices(choices: any): any {
    const mappedChoices: any = [];
    Object.keys(choices).forEach((key: any) => {
      const choice: any = choices[key];

      if (choice.button) {
        mappedChoices.push('' + key + '-' + choice.button.code);
      } else {
        mappedChoices.push(choice);
      }
    });

    return mappedChoices;
  }

  async stepDone(step: StepInterface, choices: any): Promise<any> {
    const mappedChoices: any = this.mapChoices(choices);

    return this.helper.stepDone(step, mappedChoices, choices);
  }

  async configureStep(step: StepInterface, choices: any): Promise<StepInterface> {
    const mappedChoices: any = this.mapChoices(choices);

    const override = await this.helper.overrideStep(step, mappedChoices, choices);
    if (false !== override) {
      if (this.store) {
        this.store.dispatch('stopLoader');
      }
      return override as StepInterface;
    }

    step.buttons = step.buttons.filter((button: ButtonInterface) => {
      let success: boolean = true;

      if (!button.options || typeof button.options.conditions === 'undefined') {
        return success;
      }

      const options = button.options;
      const conditions = options.conditions || [];
      if (0 === conditions.length) {
        return success;
      }

      // if there are conditions but no choices, it is guaranteed to be excluded.
      if (!choices || 0 === Object.keys(choices).length) {
        return false;
      }

      conditions.forEach((condition: ConditionInterface) => {
        if ('OR' === condition.type) {
          if (!this.andOrIncludes(false, condition.values, mappedChoices)) {
            success = false;
          }
        } else {
          if (!this.andOrIncludes(true, condition.values, mappedChoices)) {
            success = false;
          }
        }
      });

      return success;
    });

    const result: any = this.helper.customizeStep(step, mappedChoices, choices);
    if (this.store) {
      this.store.dispatch('stopLoader');
    }

    return result;
  }

  andOrIncludes(isAnd: boolean, collection: any[], subset: any[]) {
    let hasOneFail = false;
    let hasOneMatch = false;

    subset.forEach((item: any) => {
      if (-1 === collection.indexOf(item)) {
        hasOneFail = true;
      } else {
        hasOneMatch = true;
      }
    });

    if (isAnd) {
      return !hasOneFail;
    }

    return hasOneMatch;
  }

  setHelper(helper: HelperInterface) {
    this.helper = helper;
  }

  init(store: Store<any>) {
    this.store = store;

    this.store.commit('SET_STEP_NUMBER', 1);

    const config: any = this.getConfig();
    this.store.commit('SET_CONFIG', config);
    this.store.commit('SET_ORIGINAL_CONFIG', config);
  }
}

export const wizardProvider: WizardProvider = new WizardProvider();
