import { ButtonInterface, StepInterface } from '@/model';
import baseConfig from './config-base';

baseConfig.user = 'uiterwaarde';

const newConfig = { ...{}, ...baseConfig };
newConfig.steps = baseConfig.steps.filter((step: StepInterface) => ['personaldata', 'confirm', 'finish'].includes(step.code));

newConfig.steps[0].buttons = newConfig.steps[0].buttons.map((button: ButtonInterface) => {
  if ('nl' === button.code) {
    button.label = 'Boek een parkeerplaats';
  }

  return button;
});

export default newConfig;
